import React, {
  useState,
  useEffect,
  useMemo,
  CSSProperties,
  forwardRef,
} from "react";
import {
  Grid,
  Image,
  Button,
  Divider,
  Modal,
  Icon,
  Transition,
  List,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import { FormattedMessage } from "react-intl";

// UI
import HospitalSearchBox from "./HospitalSearchBox";
import MobSkeletonPHR from "../phr/MobSkeletonPHR";
import { fontWeight } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Slide,
} from "@material-ui/core";

// Component
import SelectDose from "./SelectDose";
import DrugDetail from "./DrugDetail";
import ModalOffMedication from "./ModalOffMedicationAll";
import moment from "moment";
import { Group } from "@material-ui/icons";

// type
import { medicationType , historyType } from "../MobMedicationInterface";
import { machineLearning } from "firebase-admin";

const COLORS = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  gray: "#E9E9E9",
  darkgrayHos: "#797878",
  light_grey: "#EEEEEE",
  darkgray: "#9F9F9F",
  orange: "#DE7800",
  super_darkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  boxcard: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    margin: "8px",
    padding: "0px 7px",
    minHeight: "140px",
  } as CSSProperties,
  titletext: {
    width: "90%",
    overflow: "hidden",
    fontSize: "16px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "2px",
    marginBottom: "3px",
    color: COLORS.primary,
  } as CSSProperties,
  titletext_bole: {
    width: "90%",
    overflow: "hidden",
    fontSize: "16px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "2px",
    marginBottom: "3px",
    color: COLORS.primary,
    fontWeight: "bold",
  } as CSSProperties,
  normaltext: {
    fontSize: "14px",
    marginBottom: "3px",
    color: COLORS.darkgrayHos,
  } as CSSProperties,
  normaltext_black: {
    fontSize: "14px",
    marginBottom: "3px",
    color: "black",
  } as CSSProperties,
  boxButtonOPD: {
    padding: "1px",
    background: COLORS.light_grey,
    borderRadius: "10px",
    marginRight: "1px",
  } as CSSProperties,
  buttonOPD: {
    margin: "0px",
    padding: "8px 25px",
    borderRadius: "10px",
    height: "30px",
    background: COLORS.gray,
  } as CSSProperties,
  imageCircular: {
    display: "flex",
    width: "45px",
    height: "45px",
    background: COLORS.superligtblue,
    borderRadius: "23px",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  skeleton_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  } as CSSProperties,
  duplicate_gird: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "13px 30px",
  } as CSSProperties,
  duplicate_button: {
    marginTop: "13px",
    color: COLORS.font_white,
    background: COLORS.orange,
    border: "0px",
    borderRadius: "5px",
    fontSize: "14px",
    padding: "5px 25px",
    fontWeight: "bold",
  } as CSSProperties,
  grid_side: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    padding: "20px 0",
  } as CSSProperties,
  offAllMed: {
    display: "flex",
    position: "absolute",
    bottom: "15px",
    justifyContent: "center",
    width: "100%",
  } as CSSProperties,
  offAllMed_button: {
    color: COLORS.font_white,
    background: COLORS.darkgray,
    border: "0px",
    borderRadius: "20px",
    fontSize: "16px",
    padding: "7px 15px",
  } as CSSProperties,
};

type MyCurrentMedicationProp = {
  onEvent: (e: any) => any;
  medicationItem: medicationType[];
  loadingMedication?: boolean;
  medicationAll: string;
  selectedHospital: any;
  hospitalList: any;
  currentMedication?: any[];
};

const TransitionDialog = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const MyCurrentMedication: React.FunctionComponent<MyCurrentMedicationProp> = (
  props
) => {
  const [medicationData, setMedicationData] = useState<any | null>([]);
  const [medicationNOFilter, setMedicationNOFilter] = useState<any | null>({});
  const [allMedicationList, setAllMedicationList] = useState<any>([]);
  const [encounter, setEncounter] = useState<string>("");
  const [opdFilter, setOpdFilter] = useState<string>("all");
  const [open, setOpen] = React.useState<boolean>(false);
  const [cardClick, setCardClick] = React.useState<string>("");
  const [openSelectDose, setOpenSelectDose] = React.useState<boolean>(false);
  const [openDrugDetail, setOpenDrugDetail] = React.useState<boolean>(false);
  const [searchHospital, setSearchHospital] = useState<string>("");
  const [medicationSelect, setMedicationSelect] = useState<any | null>([]);
  
  useEffect(() => {
    if (props.medicationItem?.length > 0 && props.currentMedication != null) {
      const medication = props.medicationItem.filter((items: medicationType) => {
        if (opdFilter !== "all") {
          return (items.history || []).some(
            (item: historyType) => item?.extra?.dispense_type === opdFilter
          );
        } else {
          return true;
        }
      });

      const groupCurrentMed = props.currentMedication?.reduce((group, item) => {
        // console.log("saika ;) reduce item", item);
        const drug = item[0];
        group[drug] = group[drug] ?? [];
        group[drug].push(item);
        return group;
      }, {});

      const groupArrays = Object.keys(groupCurrentMed)?.map((drug) => {
        const drugName = drug;
        return {
          drugName,
          item: groupCurrentMed[drug],
        };
      });

      // console.log("saika ;) groupArrays ", groupArrays);
      const newMedication = medication.map((medicationItems: medicationType) => {
        let newHistory: any;
        groupArrays.map((groupArraysItem: any) => {
          if (groupArraysItem.drugName === medicationItems.id) {
            newHistory = (medicationItems?.history || []).map((historyItem: historyType) => {
              const stateDrug = groupArraysItem.item
                .map((item: any) => {
                  // console.log("saika ;) medicationItems.id ",medicationItems.id," : ",item)
                  // console.log("saika ;) historyItem.encounter ",historyItem.encounter)
                  // console.log("saika ;) item.includes(historyItem.encounter) ", item.includes(historyItem.encounter))
                  if (item.includes(historyItem.encounter)) {
                    return { state: item[2] };
                  }
                })
                .filter((item: any) => item !== undefined);
              // console.log("saika ;) stateDrug ", stateDrug)
              if (stateDrug.length > 0 && stateDrug[0] !== undefined) {
                return { ...historyItem, state: stateDrug[0].state };
              } else {
                return { ...historyItem, state: "" };
              }
            });
            // console.log("saika ;) newHistory ", newHistory);
          }
        });

        if (newHistory == null) {
          newHistory =(medicationItems?.history || []).map((historyItem: historyType) => {
            return { ...historyItem, state: "" };
          });
        }

        return { ...medicationItems, history: newHistory };
      });

      const medicationFilterON = newMedication.filter((items: any) => {
        return items.history.some(
          (item: any) => item.state === "ON" || item.state === ""
        );
      });

      const medicationFilterOFF = newMedication.filter((items: any) => {
        return items.history.some((item: any) => item.state === "OFF");
      });

      // console.log("saika ;) newMedication ", newMedication);
      console.log("saika ;) medicationFilterON ", medicationFilterON);
      console.log("saika ;) medicationFilterOFF ", medicationFilterOFF);
      // console.log("saika ;) medicationFilterOFF ", medicationFilterOFF.length);
      // console.log("saika ;) medication.length ", props.medicationItem.length);
      
      // เคสนี้ bug มียาตัวเดียวกันทั้ง on/off แต่มาจากคนละ en
      // currentMedication = [
      //     [
      //         "MTBTYLE0500M48130698||3",
      //         "O01-24-0011620",
      //         "OFF"
      //     ],
      //     [
      //         "MTBTYLE0500M48130698||3",
      //         "O01-24-0011703",
      //         "ON"
      //     ],
      //     [
      //         "MTBTYLE0500M48130313||4",
      //         "O01-24-0011620",
      //         "ON"
      //     ]
      // ]
      
      if (medicationFilterON.length === props.medicationItem.length) {
        setEncounter(medicationFilterON[0]?.history?.[0]?.encounter || "");
        setMedicationData(medicationFilterON || []);
        setAllMedicationList(medicationFilterON || []);
        setMedicationNOFilter(newMedication || null);
      } else {
        if (medicationFilterOFF.length === props.medicationItem.length) {
          setEncounter("");
          setMedicationData([]);
          setAllMedicationList([]);
        } else {
          setEncounter(medicationFilterON[0]?.history?.[0]?.encounter || "");
          setMedicationData(medicationFilterON || []);
          setAllMedicationList(medicationFilterON || []);
          setMedicationNOFilter(newMedication || null);
        }
      }
      
    } else {
      setEncounter("");
      setMedicationData([]);
      setAllMedicationList([]);
    }
  }, [
    props.medicationItem,
    props.loadingMedication,
    opdFilter,
    props.currentMedication,
  ]);

  useEffect(() => {
    const list = allMedicationList.filter((item: medicationType) =>
      item.hospital_name?.toLowerCase()?.includes(searchHospital.toLowerCase())
    );

    setMedicationData(list);
  }, [searchHospital]);

  const hospitalList = useMemo(() => {
    if (props.hospitalList !== undefined) {
      return Array.from(
        new Map(
          props.hospitalList.map((item: any) => [item["name"], item])
        ).values()
      );
    } else {
      const arrayFlat = allMedicationList.map((item: medicationType, index: any) => {
        const name = item.hospital_name || props.selectedHospital?.name;
        return { name, code: name };
      });

      return Array.from(
        new Map(arrayFlat.map((item: any) => [item["name"], item])).values()
      );
    }
  }, [allMedicationList, props.selectedHospital, props.hospitalList]);

  const handleChangeFilter = (type: string) => {
    setOpdFilter(type);
  };

  const handleSearch = (value: string) => {
    setSearchHospital(value);
  };

  const handleCloseSelecteDose = () => {
    setOpenSelectDose(false);
  };

  const handleCloseDrugDetail = () => {
    setOpenDrugDetail(false);
  };

  const handleClickCard = (index: string) => {
    if (cardClick === index) {
      setCardClick("");
    } else {
      setCardClick(index);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDrugDetail = (item: any) => {
    setOpenDrugDetail(true);
    setMedicationSelect(item);
  };

  const handleOpenSelectDose = (item: any) => {
    setOpenSelectDose(true);
    setMedicationSelect(item);
  };

  const handleOffAllMedication = () => {
    setOpen(false);
    let allState: any[] = [];
    medicationNOFilter.map((items: any) => {
      items.history.map((item: any) => {
        allState.push([items.id, item.encounter, "OFF"]);
      });
    });
    // console.log("saika ;) allState ", { multi: allState });
    props.onEvent({
      message: "handleUpdateCurrentMed",
      params: { multi: allState },
    });
  };

  const drugList = (
    hospitalName: any,
    historySize: number,
    item: any,
    index: any,
    indexDrug: any
  ) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            ...styles.normaltext,
            display: cardClick === `${index}` ? "inline-block" : "none",
          }}
        >
          {hospitalName || props.selectedHospital?.name || ""}
        </div>
        <div
          style={{
            ...styles.normaltext,
            display: cardClick === `${index}` ? "inline-block" : "none",
          }}
        >
          {item.order_date
            ? moment(item.order_date).format("DD MMM YYYY")
            : "-"}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {historySize > 1 && (
            <List
              bulleted
              style={{
                position: "absolute",
                color: COLORS.primary,
                margin: "0",
              }}
            >
              <List.Item />
            </List>
          )}

          <div
            style={{
              ...styles.normaltext_black,
              width: "90%",
            }}
          >
            {item.note.medication}
          </div>
          <div
            style={{
              width: "16px",
              paddingTop: "2px",
            }}
          >
            {item.extra?.dispense_type === "HOSP" ? (
              <Image src="images/drug/hospital_gray.png" />
            ) : item.extra?.dispense_type === "OM" ? (
              <Image src="images/drug/home_gray.png" />
            ) : null}
          </div>
        </div>
        <div>
          <div
            style={{
              ...styles.normaltext,
              color: COLORS.super_darkgray,
              whiteSpace: 'pre-wrap',
              display: cardClick === `${index}` ? "inline-block" : "none",
            }}
          >
            {"Qty : "}
          </div>
          <div
            style={{
              ...styles.normaltext,
              whiteSpace: 'pre-wrap',
              display: cardClick === `${index}` ? "inline-block" : "none",
            }}
          >
             { (item.ph_qty_ord || "").length > 0 ? `X ${item.ph_qty_ord} ${item.unit}`: "-"}
          </div>
        </div>
        <div>
          <div
            style={{
              ...styles.normaltext,
              color: COLORS.super_darkgray,
              display: cardClick === `${index}` ? "inline-block" : "none",
            }}
          >
            {"Exp : "}
          </div>
          <div
            style={{
              ...styles.normaltext,
              display: cardClick === `${index}` ? "inline-block" : "none",
            }}
          >
            {item.exp ? moment(item.exp).format("DD MMM YYYY") : "-"}
          </div>

          {historySize > 1 && indexDrug !== historySize - 1 ? (
            <Divider
              style={{
                background: COLORS.superligtblue,
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  console.log(medicationData, encounter);

  return (
    <div style={styles.parentbox}>
      {/* Search OPD medication */}
      {props.loadingMedication ? (
        <>
          <div style={styles.skeleton_div}>
            <MobSkeletonPHR />
          </div>
        </>
      ) : (
        <>
          {props.medicationAll === "All" && (
            <HospitalSearchBox
              hospitalList={hospitalList}
              onSearchChange={handleSearch}
            />
          )}
          {/* filter OPD medication */}
          {props.medicationItem?.length > 0 && (
            <div
              style={{
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{ width: "35px", minHeight: "35px", marginRight: "5px" }}
              >
                {encounter?.charAt(0) === "O" ? (
                  <Image src="images/drug/icon_opd.png" />
                ) : encounter?.charAt(0) === "E" ? (
                  <Image src="images/drug/icon_er.png" />
                ) : encounter?.charAt(0) === "I" ? (
                  <Image src="images/drug/icon_ipd.png" />
                ) : null}
              </div>
              <div>
                <Grid columns={3} divided style={styles.boxButtonOPD}>
                  <Grid.Column style={{ padding: "0px 5px 0px 0px" }}>
                    <Button
                      style={{
                        ...styles.buttonOPD,
                        padding: "8px 22px",
                        fontsize: "16px",
                        background:
                          opdFilter === "all"
                            ? COLORS.font_white
                            : COLORS.light_grey,
                      }}
                      onClick={() => {
                        handleChangeFilter("all");
                      }}
                    >
                      ALL
                    </Button>
                  </Grid.Column>
                  <Grid.Column style={{ padding: "0px 5px 0px 5px" }}>
                    <Button
                      style={{
                        ...styles.buttonOPD,
                        background:
                          opdFilter === "HOSP"
                            ? COLORS.font_white
                            : COLORS.light_grey,
                      }}
                      onClick={() => {
                        handleChangeFilter("HOSP");
                      }}
                    >
                      <Image
                        src="images/drug/hospital_gray.png"
                        style={{ height: "14px" }}
                      />
                    </Button>
                  </Grid.Column>
                  <Grid.Column style={{ padding: "0px 0px 0px 5px" }}>
                    <Button
                      style={{
                        ...styles.buttonOPD,
                        padding: "8px 26px",
                        background:
                          opdFilter === "OM" ? COLORS.font_white : COLORS.gray,
                      }}
                      onClick={() => {
                        handleChangeFilter("OM");
                      }}
                    >
                      <Image
                        src="images/drug/home_gray.png"
                        style={{ height: "14px" }}
                      />
                    </Button>
                  </Grid.Column>
                </Grid>
              </div>
              {medicationData.length > 0 ? (
                <div style={styles.offAllMed}>
                  <button
                    style={styles.offAllMed_button}
                    onClick={() => setOpen(true)}
                  >
                    OFF all medicines
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* card medication */}

          {medicationData.length > 0 ? (
            medicationData.map((item: any, index: any) =>
              item.history.some((item: any) => item.state === "") ? (
                <div style={styles.boxcard}>
                  <Grid
                    columns={3}
                    style={{
                      display: "grid",
                      width: "100%",
                      gridTemplateColumns: "35px 1fr",
                      margin: "0",
                    }}
                    key={1}
                  >
                    <Grid.Column style={styles.grid_side}>
                      <div onClick={() => handleOpenDrugDetail(item)}>
                        <Image
                          style={{ width: "27px", height: "27px" }}
                          src={
                            item.route_image ||
                            "/images/drug/icon_no_medication1.png"
                          }
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column style={{ width: "100%", padding: "10px" }}>
                      <Grid.Row style={{ position: "relative" }}>
                        <div style={{ display: "inline-grid", width: "100%" }}>
                          <div
                            style={{
                              ...styles.titletext_bole,
                              display: "inline-block",
                            }}
                          >
                            {item.name}
                          </div>
                        </div>
                      </Grid.Row>
                      <Grid.Row>
                        <div style={styles.duplicate_gird}>
                          <div>
                            <Image src="/images/drug/icon_drug-duplicate.png" />
                          </div>
                          <div>
                            <button
                              style={styles.duplicate_button}
                              onClick={() => handleOpenSelectDose(item)}
                            >
                              Please check
                            </button>
                          </div>
                        </div>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                </div>
              ) : (
                <div
                  style={styles.boxcard}
                  onClick={() => handleClickCard(`${index}`)}
                >
                  <Grid
                    columns={3}
                    style={{
                      display: "grid",
                      width: "100%",
                      gridTemplateColumns: "35px 1fr",
                      margin: "0",
                    }}
                    key={index}
                  >
                    <Grid.Column
                      style={{
                        ...styles.grid_side,
                        padding:
                          cardClick === `${index}`
                            ? "60px 0 "
                            : item.history.length > 1
                            ? "20px 0"
                            : "40px 0",
                      }}
                    >
                      <div onClick={() => handleOpenDrugDetail(item)}>
                        <Image
                          style={{ width: "27px", height: "27px" }}
                          src={
                            item.route_image ||
                            "/images/drug/icon_no_medication1.png"
                          }
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column style={{ width: "100%", padding: "10px" }}>
                      <Grid.Row style={{ position: "relative" }}>
                        <div style={{ display: "inline-grid", width: "100%" }}>
                          <div
                            style={{
                              ...styles.titletext_bole,
                              display: "inline-block",
                              paddingBottom: "8px",
                            }}
                          >
                            {item.name}
                          </div>
                          <div
                            style={{
                              ...styles.titletext,
                              display:
                                cardClick === `${index}`
                                  ? "inline-block"
                                  : "none",
                              paddingBottom: "18px",
                            }}
                          >
                            {item.name}
                          </div>
                        </div>
                        {item.history ? (
                          item.history?.filter((historyItem: historyType)=> historyItem?.state !== "OFF" )?.map((historyItem: historyType, indexDrug: any) => (
                            <>
                              {opdFilter !== "all" ? (
                                <>
                                  {historyItem.extra?.dispense_type === opdFilter &&
                                    drugList(
                                      item.hospital_name,
                                      item.history.filter((historyItem: historyType)=> historyItem.state !== "OFF" ).length,
                                      historyItem,
                                      index,
                                      indexDrug
                                    )}
                                </>
                              ) : (
                                drugList(
                                  item.hospital_name,
                                  item.history?.filter((historyItem: historyType)=> historyItem?.state !== "OFF" )?.length,
                                  historyItem,
                                  index,
                                  indexDrug
                                )
                              )}
                            </>
                          ))
                        ) : (
                          <div
                            style={{
                              ...styles.normaltext,
                              display:
                                cardClick === `${index}`
                                  ? "inline-block"
                                  : "none",
                            }}
                          >
                            {item.name}
                          </div>
                        )}
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0 10px 12px 0",
                    }}
                  >
                    <Image
                      src="images/drug/icons_script.png"
                      style={{ width: "35px", alignSelf: "flex-start" }}
                      onClick={() => handleOpenSelectDose(item)}
                    />
                  </div>
                </div>
              )
            )
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "35%",
                }}
              >
                <Image
                  src="images/drug/icon_no_medication.png"
                  style={{ width: "90px", alignSelf: "flex-start" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  textAlign: "center",
                  whiteSpace: "pre-wrap",
                }}
              >
                <FormattedMessage id={"bplusClinicKey928"} />
              </div>
            </div>
          )}

          {/* Popup Select Dose medication */}

          <Dialog
            fullScreen
            open={openSelectDose}
            // @ts-ignore
            TransitionComponent={TransitionDialog}
          >
            <div style={{ width: "100%" }}>
              <Dimmer inverted active={props.loadingMedication}>
                <Loader>Loading</Loader>
              </Dimmer>
              <SelectDose
                onClose={handleCloseSelecteDose}
                onEvent={props.onEvent}
                medicationItem={medicationSelect}
                selectedHospital={props.selectedHospital}
              />
            </div>
          </Dialog>

          {/* Popup DrugDetail medication */}

          <Dialog
            fullScreen
            open={openDrugDetail}
            // @ts-ignore
            TransitionComponent={TransitionDialog}
          >
            <div style={{ width: "100%" }}>
              <DrugDetail
                onClose={handleCloseDrugDetail}
                onEvent={props.onEvent}
                medicationItem={medicationSelect}
                selectedHospital={props.selectedHospital}
              />
            </div>
          </Dialog>

          {/* Popup off all medication */}
          <Modal
            onClose={() => handleClose}
            open={open}
            style={{
              borderRadius: "5px",
            }}
          >
            <ModalOffMedication
              decline={handleClose}
              accept={handleOffAllMedication}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default React.memo(MyCurrentMedication);
