import React, { useState, useEffect } from "react";
import { Grid, Image } from "semantic-ui-react";
// MUI
import MobileStepper from "@mui/material/MobileStepper";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import Cookies from "js-cookie";
import SwipeableViews from "react-swipeable-views";

// CSS
import "./HistoryCSS.scss";

// Types
type ImplantDetailProps = {
  implantList?: any[];
  selectedImplant?: any;
  patientDetail?: any;
  selectedHospital?: any;
};

// Const
const LANGUAGE = ["th", "th-TH", "th-th"].includes(
  Cookies.get("language") || navigator?.language?.split(/[-_H]/)[0]
)
  ? "th-TH"
  : "en-US";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superdarkgray: "#393939",
  BlueBDMS: "#0147A3",
  darkgrayHos: "#797878",
};

const styles = {
  text_black_16_Bold: {
    fontFamily: "Montserrat, Noto Sans Thai",
    fontSize: "16px",
    fontWeight: "bold",
    color: "black",
  } as CSSProperties,
  text_bluebdms_16: {
    fontFamily: "Montserrat, Noto Sans Thai",
    marginBottom: "16px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.primary,
  } as CSSProperties,
  flex_start: {
    display: "flex",
    justifyContent: "stert",
  } as CSSProperties,
  text_superdarkgray_16: {
    margin: "2px 5px",
    fontSize: "16px",
    color: COLOR.superdarkgray,
  } as CSSProperties,
  hospital_label: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 0 0 0",
    fontSize: "14px",
    color: COLOR.font_white,
  } as CSSProperties,
  extend_Detail: {
    paddingBottom:"0",
  } as CSSProperties,
};

const useStyles = makeStyles((theme) => ({
  implant_detail: {
    width: "100%",
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    overflowWrap: "anywhere",
    "& span": {
      paddingRight: "0.25rem",
    },
  },
}));

const ImplantDetail: React.FunctionComponent<ImplantDetailProps> = (props) => {
  const classes = useStyles();

  const checkLanguage = (text: any) => {
    if (text != null) {
      const textSplit = text?.split("/");
      if (LANGUAGE === "en-US") {
        return textSplit[0] || "";
      } else {
        return textSplit[1] || "";
      }
    }
  };

  const convertDate = (selectedDate: string | undefined): string => {
    if (!selectedDate) return "";
    // "1982-04-01" -> "01/04/2525"
    const [year, month, day] = selectedDate.split("-");
    let resultYear = parseInt(year, 10);

    if (LANGUAGE === "th-TH") {
      resultYear = resultYear + 543;
    }

    return `${day}/${month}/${resultYear}`;
  };

  console.log("patientDetail", props.patientDetail);
  console.log("selectedImplant", props.selectedImplant);

  return (
    <div style={{ height: "auto", overflowY: "auto" }}>
      <div className="ImplantDetail">
        <div>
          <>
            <div>
              <div
                className="card2"
                style={{
                  padding: "16px 20px 24px",
                  gap: "16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={styles.text_black_16_Bold}>Patient Information</div>

                <div className="info-details">
                  <div className="detail-item" style={styles.extend_Detail}>
                    <p className="label">HN:</p>
                    <p className="value">{props.selectedHospital?.hn}</p>
                  </div>

                  <div className="detail-item" style={styles.extend_Detail}>
                    <p className="label">{LANGUAGE === "th-TH" ? "ชื่อคนไข้:" : "Patient name:" }</p>
                    <p className="value">
                      {props.patientDetail?.first_name}{" "}
                      {props.patientDetail?.last_name}
                    </p>
                  </div>

                  <div className="detail-item" style={styles.extend_Detail}>
                    <p className="label">{LANGUAGE === "th-TH" ? "วันเกิด:" : "Date of birth:" }</p>
                    <p className="value">
                      {convertDate(
                        props.patientDetail?.dob.format("DD/MM/YYYY")
                      ).toString()}{" "}
                      (DD/MM/YYYY)
                    </p>
                  </div>
                </div>

                <div style={{}}>
                  <Grid style={{ margin: "0px", lineHeight: 1.5, gap: "16px"}}>
                    <Grid.Row>
                      <div style={styles.text_black_16_Bold}>{"Procedure"}</div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="value">
                        {props.selectedImplant?.procedurE_DESC}
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4} style={{ padding: "0" }}>
                        <div className="label">{LANGUAGE === "th-TH" ? "วันที่รักษา:" : "Date:" }</div>
                      </Grid.Column>
                      <Grid.Column width={12} style={{ padding: "0" }}>
                        <div className="value">
                          {convertDate(
                            props.selectedImplant?.procedurE_Date.format(
                              "DD/MM/YYYY"
                            )
                          ).toString()}{" "}
                          (DD/MM/YYYY)
                          {/* {moment(props.selectedImplant?.procedurE_Date)?.format(
                            "DD/MM/YYYY"
                          ) || props.selectedImplant?.procedurE_Date} */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4} style={{ padding: "0" }}>
                        <div className="label">{LANGUAGE === "th-TH" ? "ชื่อแพทย์:" : "Doctor:" }</div>
                      </Grid.Column>
                      <Grid.Column width={12} style={{ padding: "0" }}>
                        <div className="value">
                          {checkLanguage(props.selectedImplant?.carpDesc) || ""}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4} style={{ padding: "0" }}>
                        <div className="label">{LANGUAGE === "th-TH" ? "โรงพยาบาล:" : "Hospital:" }</div>
                      </Grid.Column>
                      <Grid.Column width={12} style={{ padding: "0" }}>
                        <div className="value">
                          {checkLanguage(props.selectedImplant?.hospital) ||
                            props.selectedImplant?.hospital}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {/* <div style={styles.hospital_label}>
                    {props.selectedImplant?.hospital}
                  </div> */}
                </div>
              </div>

              <div className="card2">
                {props.selectedImplant?.implant
                  ? props.selectedImplant?.implant.map(
                      (implantItem: any, index: number) => (
                        <div style={{ lineHeight: 1.5 }}>
                          <Grid style={{ margin: "0" }}>
                            <Grid.Row>
                              <div style={styles.text_bluebdms_16}>
                                {`Implant ${index + 1}`}
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.implant_detail}>
                                <div className="detail-item">
                                  <span className="label">{"Name : "}</span>
                                  <span className="value-right">
                                    {implantItem.imP_DESC
                                      ? implantItem.imP_DESC
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.implant_detail}>
                                <div className="detail-item">
                                  <span className="label">
                                    {"Lot Number : "}
                                  </span>
                                  <span className="value-right">
                                    {implantItem.imP_LOT
                                      ? implantItem.imP_LOT
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.implant_detail}>
                                <div className="detail-item">
                                  <span className="label">{"Brand : "}</span>
                                  <span className="value-right">
                                    {implantItem.imP_BRAND
                                      ? implantItem.imP_BRAND
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.implant_detail}>
                                <div className="detail-item">
                                  <span className="label">{"MRI1 : "}</span>
                                  <span className="value-right">
                                    {implantItem.mri ? implantItem.mri : "-"}
                                  </span>
                                </div>
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.implant_detail}>
                                <div className="detail-item">
                                  <span className="label">
                                    {"Remove Date : "}
                                  </span>
                                  <span className="value-right">
                                    {implantItem.removE_DATE
                                      ? implantItem.removE_DATE
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.implant_detail}>
                                <div className="detail-item">
                                  <span className="label">{"Remark1 : "}</span>
                                  <span className="value-right">
                                    {implantItem.remark
                                      ? implantItem.remark
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* <div
                              style={{
                                ...(!implantItem.imP_base64 && {
                                  display: "none",
                                }),
                              }}
                            > */}
                              <Image
                                style={{ width: "100%", height: "auto" , marginBottom: "24px"}}
                                rounded
                                src={`data:image/jpeg;base64,${implantItem.imP_base64}`}
                              />
                              {/* </div> */}
                            </Grid.Row>
                          </Grid>
                        </div>
                      )
                    )
                  : null}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ImplantDetail);
