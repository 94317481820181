import WasmController from "react-lib/frameworks/WasmController";

import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";

// api
// USER
import MobileRequestToken from "issara-sdk/apis/MobileRequestTokenAPI_users";
import UserSetPassword from "issara-sdk/apis/UserSetPasswordAPI_users";
import RequestTokenAPI from "issara-sdk/apis/RequestTokenAPI_users";
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";

// Profile
import ProxyMyProfile from "issara-sdk/apis/ProxyMyProfile_profileM";
import ProfileApi from "issara-sdk/apis/ProxyMyProfile_profile_apiM";
// PRX
import CheckRequireDiagRuleView from "issara-sdk/apis/CheckRequireDiagRuleView_apps_PRX";
import V4RegisterHNView from "issara-sdk/apis/V4RegisterHNView_apps_PRX";
import MyProxyPatientView from "issara-sdk/apis/MyProxyPatientView_apps_PRX";
import ProxyPatientDetail from "issara-sdk/apis/ProxyPatientDetail_apps_PRXM";
import ListConsentsView from "../issara-sdk/apis/ListConsentsView_apps_PRX";
import V3PatientGetHN from "issara-sdk/apis/V3PatientGetHN_apps_PRX";
import SmartUserState from "issara-sdk/apis/SmartUserState_apps_PRX";
import SmartRetrieveProfile from "issara-sdk/apis/SmartRetrieveProfile_apps_PRX";
import SmartRegister from "issara-sdk/apis/SmartRegister_apps_PRX";
import SmartRequestOTP from "issara-sdk/apis/SmartRequestOTP_apps_PRX";
import SingOutAPI from "issara-sdk/apis/MobileDeviceSignOutAPI_users";
import ProxyOrganizationView from "issara-sdk/apis/ProxyOrganizationView_apps_PRX";

import CONFIG from "config/config";

// Types
import {
  registerInfo,
  dropDownOption,
  profileInfo,
  devModeType,
  LastLoginType,
} from "bplus-lib/register/TypeModal";

// Interface
import * as KYCInterfaceI from "../bplus-lib/register/KYCInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as MobSmartAppointmentI from "./MobSmartAppointmentInterface";

import { RegisterHNAndCheckConsent } from "./MobAppointmentInterface";
import SyncAllProfileConsent from "issara-sdk/apis/SyncAllProfileConsent_apps_PRX";

export type State = Partial<{
  apiToken: string;
  permit: string;
  userId: string | number;
  device_id: any;
  language: "en-US" | "th-TH";
  registerInfo: registerInfo;
  refCode: string;
  devMode: devModeType;
  loading: boolean;
  loggedin: boolean;
  lastLogin: LastLoginType;
  loadingStatus: Record<string, any>;
  errorMessage: Record<string, any>;
  successMessage: Record<string, any>;
  platform: string;
  profileInfo: profileInfo;
  dropDownOption: dropDownOption;
  consentStatus: boolean;
  uiMode?: "LOGIN" | "NEW_USER" | "MATCH" | "NOT_MATCH" | "FORGOT_PASS";
  rotateType?: "Portrait" | "LandscapeLeft" | "LandscapeRight" | "PortraitUpsideDown" ;

  hospitalList: any[];
  loadingHospital: boolean;
  selectedHospital: any;

  organizationList: any[];
  myProfileDetail: Record<string, any>;
  safeAreaTop?: string;
}> &
  KYCInterfaceI.State &
  SelectHospitalI.State;

export const StateInitial: State = {
  apiToken: "",
  permit: "",
  userId: "",
  loggedin: false,
  platform: "",
  device_id: "",
  devMode: {},
  uiMode: "NEW_USER",
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",

  registerInfo: {},
  refCode: "",
  loading: false,
  lastLogin: {},
  profileInfo: {},
  loadingStatus: {},
  errorMessage: {},
  successMessage: {},
  rotateType: "Portrait",
  safeAreaTop: undefined,
  ...KYCInterfaceI.StateInitial,
  ...SelectHospitalI.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "GetLoginInfo"; params: {} }
  | {
      message: "HandleRegister";
      params: { history: any; forgotPass?: boolean };
    }
  | {
      message: "HandleRequestOTP";
      params: { history: any; otherUsername?: any };
    }
  | { message: "HandleForgotPassword"; params: { history: any } }
  | { message: "HandleRequestToken"; params: { history: any } }
  | { message: "HandleSetPassword"; params: { history: any } }
  | { message: "HandleGetUserProfile"; params: { loading?: boolean } }
  | {
      message: "HandleSetProfileInfo";
      params: { key: string; value: any };
    }
  | {
      message: "HandleUpdateProfile";
      params: { history: any };
    }
  | {
      message: "HandleLogin";
      params: { history: any };
    }
  | {
      message: "HandleLogOut";
      params: { history: any };
    }
  | { message: "HandleGotoMainScreen"; params: {} }
  | {
      message: "HandleSetValueRegisterInfo";
      params: { key: string; value: any };
    }
  | { message: "HandleUpdateStatuseKYC"; params: Record<string, any> }
  | { message: "HandleGetListOrganizations"; params: { status: string } }
  | { message: "HandleRegisterHN"; params: { code: string } }
  | {
      message: "HandleCheckConsentHospitalForm";
      params: { code: string; history: any };
    }
  | { message: "HandleCheckPatientHasHospitial"; params: {} }
  | { message: "CheckUserProfileRequiredField"; params: {} }
  | { message: "HandleGetStatuseKYC"; params: {} }
  | { message: "HandleCheckMakeAppointment"; params: { history: any } }
  | { message: "GetMyProfileDetail"; params: {} }
  | {
      message: "HandlerPostRetrieveProfile";
      params: {
        redirect?: string;
        hn: string;
        code: string;
        card: string;
        btnAction: string;
        name: string;
        initHospital?: boolean;
      };
    }
  | KYCInterfaceI.Event
  | SelectHospitalI.Event
  | MobSmartAppointmentI.Event;

export type Data = {};

const required = {
  username: {
    input: ["username"],
    check: {
      prefix: ["0-9"],
      minLength: 10,
      message: "incorrect",
    },
  },
  password: {
    input: ["password", "confirmPassword"],
    check: {
      prefix: ["A-Z", "a-z", "0-9"],
      minLength: 8,
      message: "รหัสผ่านที่ท่านกำหนด ไม่ตรงตามเงื่อนไข",
    },
  },
  info: {
    input: ["firstName", "lastName", "birthdate"],
    check: { prefix: [] },
  },
  hn: {
    input: ["hn"],
    check: {
      prefix: ["0-9"],
      length: 7,
    },
  },
  phoneNum: {
    input: ["phoneNum"],
    check: {
      prefix: ["0-9"],
      length: 10,
    },
  },
  cid: {
    input: ["cid"],
    check: {
      prefix: ["0-9"],
      length: 13,
      message: "incorrect",
    },
  },
};

const REGEX_PATIENT = /\['([^']+)?(?=\()/g;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
  SelectHospitalI.SelectedInitialHospital(controller, params);
};

export const HandleSetProfileInfo: Handler = (controller, params) => {
  let { profileInfo } = controller.getState();
  const { key, value } = params;

  if (profileInfo) {
    (profileInfo as any)[key] = value;
  } else {
    profileInfo = {} as any;
    (profileInfo as any)[key] = value;
  }

  controller.setState({
    registerInfo: {
      ...(profileInfo || ({} as any)),
      error: profileInfo?.error || ({} as any),
    },
  });
};

export const HandleSetValueRegisterInfo: Handler = (controller, params) => {
  let { registerInfo } = controller.getState();
  const { key, value } = params;

  if (registerInfo) {
    (registerInfo as any)[key] = value;
  } else {
    registerInfo = {} as any;
    (registerInfo as any)[key] = value;
  }

  controller.setState({
    registerInfo: {
      ...(registerInfo || ({} as any)),
      error: registerInfo?.error || ({} as any),
    },
  });
};

export const HandleRegister: Handler = async (controller, params) => {
  const { registerInfo, device_id } = controller.getState();
  const { history, forgotPass } = params;
  controller.setState({
    loading: true,
  });

  if (forgotPass) {
    if (!registerInfo?.valuecid) {
      controller.setState({
        loading: false,
        registerInfo: {
          ...registerInfo,
          error: {
            ...(!registerInfo?.valuecid && {
              valuecid: "none-file",
            }),
          },
        },
      });
      return;
    } else {
      controller.setState({
        loading: false,
        uiMode: "FORGOT_PASS",
      });
      history.push("/register/confirm-otp/?app=MobRegister");
      return;
    }
  }

  let date = {};
  let checkValuecid: any;
  let checkUsername: any;
  if (registerInfo?.typeUsername === "email") {
    checkUsername = checkValidEmail(registerInfo?.username || "");
    date = { ...date, username: registerInfo?.username, method: "mail" };
  } else {
    checkUsername = checkValidInput({
      ...required["username"].check,
      text: registerInfo?.username || "",
    });
    date = { ...date, username: registerInfo?.username, method: "otp" };
  }

  if (registerInfo?.typeId === "hn") {
    if (registerInfo?.valueHn) {
      let hn = (registerInfo?.valueHn || "").replace(/-/g, "");

      hn = hn.replace(
        /\d{2}/g,
        (...reg) => `${reg[0]}${reg[1] <= 2 ? "-" : ""}`
      );

      const hnReplaced = (registerInfo?.valueHn || "").replace(/-/g, "");
      if (hnReplaced.length === 10) {
        checkValuecid = { valid: true };
        date = { ...date, hn };
      } else {
        checkValuecid = { valid: false, message: "incorrect" };
      }
    } else {
      checkValuecid = { valid: false };
    }
  } else if (registerInfo?.typeId === "cid") {
    checkValuecid = checkValidInput({
      ...required["cid"].check,
      text: registerInfo?.valuecid || "",
    });
    date = { ...date, cid: registerInfo?.valuecid };
  } else if (registerInfo?.typeId === "passport") {
    if (registerInfo?.valuecid) {
      if (registerInfo?.valuecid.length >= 7) {
        checkValuecid = { valid: true };
        date = { ...date, cid: registerInfo?.valuecid };
      } else {
        checkValuecid = { valid: false, message: "incorrect" };
      }
    } else {
      checkValuecid = { valid: false };
    }
  }

  if (!checkValuecid?.valid || !checkUsername?.valid) {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          ...(!checkUsername?.valid && {
            username: checkUsername?.message || "none-file",
          }),
          ...(!checkValuecid?.valid && {
            valuecid: checkValuecid?.message || "none-file",
          }),
        },
      },
    });
    return;
  }

  const [res, error] = await SmartRegister.post({
    data: date,
  });

  if (res) {
    switch (res?.status) {
      case "success": {
        controller.setState({
          refCode: "",
          loading: false,
          uiMode: "MATCH",
          registerInfo: {
            ...registerInfo,
            options: res?.options || {},
            ...(registerInfo?.typeId === "hn" && {
              typeId: "cid",
              valuecid: res.cid,
            }),
          },
        });
        break;
      }
      case "need_confirm": {
        controller.setState({
          refCode: "",
          loading: false,
          uiMode: "NOT_MATCH",
          registerInfo: { ...registerInfo, options: res?.options || {} },
        });
        break;
      }
      case "new_record": {
        controller.setState({
          refCode: "",
          loading: false,
          uiMode: "NEW_USER",
          registerInfo: { ...registerInfo },
        });
        break;
      }
      default: {
        controller.setState({
          refCode: "",
          loading: false,
          uiMode: "NEW_USER",
        });
      }
    }

    history.push("/register/confirm-otp/?app=MobRegister");
  } else {
    if (error) {
      let deviceDetail: any;
      if (globalThis.mobile?.getIosDeviceDetail) {
        const nativeDetail = await globalThis.mobile.getIosDeviceDetail;
        deviceDetail = JSON.parse(nativeDetail);
      } else if (globalThis.mobile?.getAndroidDeviceDetail) {
        const nativeDetail = await globalThis.mobile.getAndroidDeviceDetail();
        deviceDetail = JSON.parse(nativeDetail);
      } else {
        deviceDetail = {
          deviceId: device_id,
          deviceName: "webpush",
        };
      }

      date = {
        username: registerInfo?.username,
        device_id: deviceDetail?.deviceId || "",
      };

      const [profileState, profileStateError] = await ProfileApi.post({
        data: date,
        pk: "state",
      });

      const checkAlreadyExists = (error: any) => {
        return (
          error?.includes("ชื่อผู้ใช้นั้นได้ถูกใช้ไปแล้ว") ||
          error?.includes("already in used") ||
          error?.includes("already exists")
        );
      };

      const errorUser =
        checkAlreadyExists(error.username?.[0]) ||
        checkAlreadyExists(error.non_field_errors?.[0]);

      if (errorUser) {
        controller.setState({
          loading: false,
          uiMode: "LOGIN",
          registerInfo: {
            ...registerInfo,
            state: profileState?.state || "REGISTER",
          },
        });
        history.push("/register/confirm-otp/?app=MobRegister");
      } else {
        controller.setState({
          loading: false,
          registerInfo: {
            ...registerInfo,
            error: {
              ...registerInfo?.error,
              ...(error.cid && { valuecid: error?.cid?.[0] }),
              ...(error.hn && { valuecid: error?.hn?.[0] }),
              ...(error.email && { username: error?.email?.[0] }),
              ...(error.username && { username: error?.username?.[0] }),
              ...(error.non_field_errors && {
                valuecid: `ไม่สามารถลงทะเบียนด้วย ${
                  registerInfo?.valuecid || registerInfo?.valueHn
                }`,
                username: `ไม่สามารถลงทะเบียนด้วย ${registerInfo?.username}`,
              }),
            },
          },
        });
      }
    }
  }
};

export const HandleForgotPassword: Handler = async (controller, params) => {
  const { registerInfo, uiMode, device_id } = controller.getState();
  const { history } = params;
  controller.setState({
    loading: true,
  });

  if (registerInfo?.valuecid === "") {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          ...(!registerInfo?.valuecid && {
            valuecid: "none-file",
          }),
        },
      },
    });

    return;
  }

  var data = {};
  if (
    registerInfo?.valuecid &&
    13 > registerInfo?.valuecid?.length &&
    registerInfo?.valuecid?.length > 9
  ) {
    let hn = (registerInfo?.valuecid || "").replace(/-/g, "");
    hn = hn.replace(/\d{2}/g, (...reg) => `${reg[0]}${reg[1] <= 2 ? "-" : ""}`);
    data = { hn: hn };
    controller.setState({
      registerInfo: {
        ...registerInfo,
        valuecid: "",
        valueHn: hn,
      },
    });
  } else {
    data = { cid: registerInfo?.valuecid };
  }

  const [res, error] = await SmartRequestOTP.post({
    data: data,
  });

  if (error) {
    const errors = registerInfo?.error || {};
    if (error.options) {
      controller.setState({
        refCode: "",
        loading: false,
        uiMode: "FORGOT_PASS",
        registerInfo: {
          ...registerInfo,
          typeUsername: "phoneNumber",
          options: error?.options || {},
        },
      });

      history.push("/register/confirm-otp/?app=MobRegister");
    } else {
      controller.setState({
        loading: false,
        registerInfo: {
          ...registerInfo,
          error: {
            ...registerInfo?.error,
            ...errors,
            message: "ข้อมูลไม่ถูกต้อง",
          },
        },
      });
    }
  }
};

export const HandleRequestOTP: Handler = async (controller, params) => {
  const { registerInfo, uiMode, device_id } = controller.getState();
  const { history } = params;
  controller.setState({
    loading: true,
  });

  let data: any = {};
  if (registerInfo?.typeUsername === "email") {
    data = { ...data, username: registerInfo?.username, method: "mail" };
  } else {
    data = { ...data, username: registerInfo?.username, method: "otp" };
  }

  if (registerInfo?.typeId === "hn") {
    data = { ...data, hn: registerInfo?.valueHn };
  } else if (registerInfo?.typeId === "cid") {
    data = { ...data, cid: registerInfo?.valuecid };
  } else if (registerInfo?.typeId === "passport") {
    data = { ...data, cid: registerInfo?.valuecid };
  } else {
    data = { ...data, cid: registerInfo?.valuecid };
  }

  // login with SMS
  if (params.smsAuth) {
    data = { username: registerInfo?.username, sms_auth: true };
  }

  controller.setState({
    lastLogin: {
      username: registerInfo?.username,
      typeLogin: params.smsAuth ? "sms" : "password",
      image: "",
    },
  });

  // case match มากกว่า 1 user ส่งไป confirm ก่อน
  if (params.otherUsername && uiMode === "NOT_MATCH") {
    let dataTmp = { ...data, confirm: params.otherUsername };
    const [res, error] = await SmartRegister.post({
      data: dataTmp,
    });

    if (res) {
      data = { ...data, username: res?.username, cid: res.cid };
      controller.setState({
        registerInfo: {
          ...registerInfo,
          ...(registerInfo?.typeId === "hn" && {
            typeId: "cid",
            valuecid: res.cid,
          }),
        },
      });
    }

    if (error) {
      if (
        error.username?.[0]?.includes("ชื่อผู้ใช้นั้นได้ถูกใช้ไปแล้ว") ||
        error.username?.[0]?.includes("already in used")
      ) {
        controller.setState({
          loading: false,
          uiMode: "LOGIN",
          registerInfo: {
            ...registerInfo,
          },
        });
        history.push("/register/confirm-otp/?app=MobRegister");
        return;
      } else if (
        (error.non_field_errors?.[0]?.includes(
          "ชื่อผู้ใช้นั้นได้ถูกใช้ไปแล้ว"
        ) ||
          error.non_field_errors?.[0]?.includes("already in used")) &&
        params.otherUsername === registerInfo?.username
      ) {
        controller.setState({
          loading: false,
          uiMode: "LOGIN",
          registerInfo: {
            ...registerInfo,
          },
        });
        history.push("/register/confirm-otp/?app=MobRegister");
        return;
      } else {
        var errorStr = "";
        if (error.non_field_errors) {
          let errorNonField = JSON.parse(
            error.non_field_errors?.[0].replace(/'/g, '"')
          );
          console.log("errorNonField:", errorNonField.username);
          errorStr = errorNonField?.username || "errorNonField";
        }

        controller.setState({
          loading: false,
          registerInfo: {
            ...registerInfo,
            error: {
              ...registerInfo?.error,
              ...(error.non_field_errors && {
                message: errorStr || "",
              }),
              ...(error.username && {
                message: error.username?.[0],
              }),
            },
          },
        });
        return;
      }
    }
  } else if (params.otherUsername && uiMode === "FORGOT_PASS") {
    if (
      registerInfo?.valuecid &&
      13 > registerInfo?.valuecid?.length &&
      registerInfo?.valuecid?.length > 9
    ) {
      let hn = (registerInfo?.valuecid || "").replace(/-/g, "");
      hn = hn.replace(
        /\d{2}/g,
        (...reg) => `${reg[0]}${reg[1] <= 2 ? "-" : ""}`
      );
      data = { hn: hn, user_hash: params.otherUsername };
    } else {
      data = { cid: registerInfo?.valuecid, user_hash: params.otherUsername };
    }
  }

  const [res, error] = await SmartRequestOTP.post({
    data: data,
  });

  if (res) {
    controller.setState({
      loading: false,
      refCode: res.ref || "",
      registerInfo: {
        ...registerInfo,
        otpCode: "",
        ...(params.smsAuth && { smsAuth: params.smsAuth }),
        username:
          uiMode === "FORGOT_PASS"
            ? res.username
            : data.username.replace("-", "@"),
        error: {
          ...registerInfo?.error,
          message: "",
        },
      },
      lastLogin: {
        typeLogin: params.smsAuth ? "sms" : registerInfo?.typeUsername,
      },
    });

    // login with SMS
    if (params.smsAuth) {
      history.push("/register/confirm-otp/?app=MobRegister&sms=true");
    }
  } else {
    const errors = registerInfo?.error || {};

    if (params.smsAuth) {
      if (error.username) {
        errors.username = params.intl.formatMessage({
          id: "profile.requiredGuide",
        });
      } else if (error.sms_auth) {
        errors.username = "ข้อมูลไม่ถูกต้อง";
      } else if (error.detail) {
        errors.username = "ไม่มีเบอร์โทรศัพท์นี้อยู่ในระบบ";
      }
    } else if (error.detail) {
      errors.message = error.detail?.[0];
    }

    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          ...errors,
        },
      },
    });
  }
};

export const HandleRequestToken: Handler = async (controller, params) => {
  const { registerInfo, refCode, device_id } = controller.getState();

  let deviceDetail: any;

  if (globalThis.mobile?.getIosDeviceDetail) {
    const nativeDetail = await globalThis.mobile.getIosDeviceDetail;
    deviceDetail = JSON.parse(nativeDetail);
  } else if (globalThis.mobile?.getAndroidDeviceDetail) {
    const nativeDetail = await globalThis.mobile.getAndroidDeviceDetail();
    deviceDetail = JSON.parse(nativeDetail);
  } else {
    deviceDetail = {
      deviceId: device_id,
      deviceName: "webpush",
    };
  }

  controller.setState({ loading: true });

  let date = {
    username: registerInfo?.username,
    ref: refCode,
    password: registerInfo?.otpCode,
    device_id: deviceDetail?.deviceId || "",
    ...(deviceDetail?.deviceType && { device_type: deviceDetail?.deviceType }),
    ...(deviceDetail?.device_name && {
      device_name: deviceDetail?.device_name,
    }),
    ...(deviceDetail?.deviceName && { device_name: deviceDetail?.deviceName }),
    device_token: deviceDetail?.deviceToken || "",
  };

  const [res, error] = await MobileRequestToken.post({ data: date });

  if (res) {
    console.log("res MobileRequestToken ", res);
    controller.setState({
      loggedin: true,
      apiToken: res.token,
      userId: res.user_id,
      permit: res.permit,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          message: "",
        },
      },
    });
    Cookies.set("apiToken", res.token, { path: "/" });
    Cookies.set("userId", res.user_id, { path: "/" });
    Cookies.set("permit", res.permit, { path: "/" });

    localStorage.removeItem("storedHospitalList");
    localStorage.removeItem("storedPreferredHospital");

    if (registerInfo?.typeUsername) {
      await ProxyMyProfile.patch({
        apiToken: res.token,
        data: {
          [registerInfo.typeUsername === "phoneNumber" ? "phone_no" : "email"]:
            registerInfo.username,
        },
      });
    }

    CheckCurrentState(controller, params);
    // if (
    //   ["ADD_OPTION"].includes(registerInfo?.state || "") &&
    //   uiMode === "LOGIN"
    // ) {
    //   CheckCurrentState(controller, { ...params, state: "COMPLETE" });
    // } else {
    //   history.push("/register/create-password/?app=MobRegister");
    // }
  } else {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          message: "incorrect",
        },
      },
    });
  }
};

/////////////////////////// SetPassword /////////////////////////

export const HandleSetPassword: Handler = async (controller, params) => {
  const { registerInfo, apiToken, permit, uiMode } = controller.getState();

  controller.setState({ loading: true });

  let isError = false;

  const checkPassword = checkValidInput({
    ...required["password"].check,
    text: registerInfo?.password || "",
  });
  const checkConfirmPassword = checkValidInput({
    ...required["password"].check,
    text: registerInfo?.confirmPassword || "",
  });

  if (
    (!checkPassword.valid || !checkConfirmPassword.valid) &&
    registerInfo?.error
  ) {
    registerInfo.error.password = checkConfirmPassword.message;
    registerInfo.error.confirmPassword = checkConfirmPassword.message;
    isError = true;
  } else {
    const username = registerInfo?.username
      ?.replace(/\./g, "\\.")
      ?.replace(/\-/g, "\\-");
    const regex = new RegExp(`[${username || ""}]+`, "g");

    const comparePassword =
      registerInfo?.password !== registerInfo?.confirmPassword;
    const intersectUsername =
      (registerInfo?.password?.length || 0) -
        (registerInfo?.password?.match(regex)?.[0]?.length || 0) <=
      0;

    if (comparePassword && registerInfo?.error) {
      registerInfo.error.message = "รหัสผ่านไม่ตรงกัน";
      isError = true;
    } else if (intersectUsername && registerInfo?.error) {
      registerInfo.error.message =
        "รหัสผ่านนี้คล้ายกับ ชื่อผู้ใช้งาน มากเกินไป";
      isError = true;
    }
  }

  if (isError) {
    controller.setState({ loading: false, registerInfo: { ...registerInfo } });
    return;
  }

  let date = {
    password: registerInfo?.password,
    confirm_password: registerInfo?.confirmPassword,
  };

  const [res, error] = await UserSetPassword.post({
    apiToken: apiToken,
    data: date,
  });

  if (error) {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: { ...registerInfo?.error, message: error },
      },
    });
  } else {
    const [status] = await GetStatuseKYC(controller, {});

    const [[patientDetail], [patientHospital]] = await Promise.all([
      ProxyMyProfile.get({
        apiToken: controller.cookies.get("apiToken"),
      }),
      GetPatientGetHN(controller, {}),
    ]);

    if (
      globalThis.mobile?.openPinCode &&
      globalThis.mobile?.saveDataLogin &&
      globalThis.mobile?.saveRegisterState
    ) {
      console.log("Checking nativeInfo");

      const dataRegister = {
        successMessage: {
          CreatePassword: {
            hospitalList: patientHospital || [],
            status,
            patient: patientDetail,
          },
        },
      };

      if (uiMode !== "FORGOT_PASS") {
        if (
          globalThis.mobile?.getIosDeviceDetail &&
          globalThis.mobile?.saveRegisterState
        ) {
          console.log("save state ios");
          var dateString = JSON.stringify(dataRegister).replaceAll(
            /"/g,
            "<BPLUS_DOUBLE_QUOTES>"
          );

          globalThis.mobile?.saveRegisterState(dateString);
        } else {
          console.log("save state android");
          globalThis.mobile?.saveRegisterState(JSON.stringify(dataRegister));
        }
      }

      controller.setState({
        lastLogin: {
          typeLogin: registerInfo?.typeUsername,
        },
        uiMode: "LOGIN",
      });

      CheckCurrentState(controller, params);
      // globalThis.mobile?.pinCode(registerInfo?.username);
    } else {
      console.log("skip native Checking");

      controller.setState({
        successMessage: {
          ...StateInitial.successMessage,
          [params.card]: {
            hospitalList: patientHospital || [],
            status,
            patient: patientDetail,
          },
        },
      });
    }

    controller.setState({ loading: false });
  }
};

/////////////////////////// EditProfile /////////////////////////

export const HandleGetUserProfile: Handler = async (controller, params) => {
  console.log("window.location.href:", window.location.href);

  controller.setState({
    loading: params.loading,
  });

  if (globalThis.mobile?.getLoginInfo) {
    globalThis.mobile?.getLoginInfo();
  }

  const options = await GetMasterOptions(controller, params);

  const dropDownOption: dropDownOption = {
    genders: [...options.gender.th, ...options.gender.en],
    prenames: [...options.prename.th, ...options.prename.en],
    nationalities: [...options.nationality.th, ...options.nationality.en],
  };

  const [res] = await getUserProfile(controller, params);

  if (res) {
    controller.setState({
      profileInfo: FormattedProfileInfo({
        profile: res,
        options,
        lang: params.lang,
      }),
      dropDownOption: dropDownOption,
      loading: false,
    });
  } else {
    controller.setState({
      profileInfo: {},
      dropDownOption: dropDownOption,
      loading: false,
    });
  }

  // CheckHnFromHospital(controller, params);
};

export const getUserProfile: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();

  const [res, error] = await ProxyMyProfile.get({
    apiToken: apiToken || Cookies.get("apiToken"),
  });

  return [res, error];
};

export const HandleUpdateProfile: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [`${params.card}_CONFIRM`]: true },
  });

  const profile = state.profileInfo || {};

  const patient = {
    last_name: profile.last_name,
    first_name: profile.first_name,
    pre_name: state.dropDownOption?.prenames?.find(
      (option: any) => option.name === profile.pre_name
    )?.id,
  };

  const nameParts = profile.ecp_full_name?.split(" ") || [];

  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");

  const profileData = {
    phone_no: profile.phone_no,
    email: profile.email || null,
    dob: profile.dob,
    gender: profile.gender,
    nationality: profile.nationality,
    ecp_first_name: firstName,
    ecp_last_name: lastName,
    ecp_phone_no: profile.ecp_phone_no,
    ecp_relationship: profile.ecp_relationship,
    address: profile.address,
    ...patient,
    ...(params.isPassport && {
      first_name_en: profile.first_name_en,
      last_name_en: profile.last_name_en,
      first_name: profile.first_name_en,
      last_name: profile.last_name_en,
    }),
  };

  const [res, error] = await ProxyMyProfile.patch({
    apiToken: state.apiToken || Cookies.get("apiToken"),
    data: profileData,
  });

  if (res) {
    const GENDERS = {
      หญิง: "F",
      ชาย: "M",
      Female: "F",
      Male: "M",
    } as any;

    await ProxyPatientDetail.patch({
      apiToken: state.apiToken || Cookies.get("apiToken"),
      pk: profile.patient,
      data: {
        middle_name: profile.middle_name,
        first_name_en: profile.first_name_en,
        middle_name_en: profile.middle_name_en,
        last_name_en: profile.last_name_en,
        birthdate: profile.dob
          ? moment(profile.dob, "YYYYMMDD").format("YYYY-MM-DD")
          : undefined,
        gender: profile.gender ? GENDERS[profile.gender] : undefined,
        ...patient,
      },
    });

    const [statusDetail] = await GetStatuseKYC(controller, {});

    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: statusDetail,
      },
      loadingStatus: {
        ...state.loadingStatus,
        [`${params.card}_CONFIRM`]: false,
      },
    });
  } else {
    controller.setState({
      profileInfo: {
        ...profile,
        errorMessage: error,
      },
      loadingStatus: {
        ...state.loadingStatus,
        [`${params.card}_CONFIRM`]: false,
      },
    });
  }
};

export const HandleLogin: Handler = async (controller, params) => {
  const { registerInfo, apiToken, permit, device_id } = controller.getState();

  let isError = false;

  if (!registerInfo?.password || !registerInfo?.username) {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          ...(!registerInfo?.password && { password: "none-file" }),
          ...(!registerInfo?.username && { username: "none-file" }),
        },
      },
    });
    isError = true;
  }

  if (isError) {
    return;
  }

  let deviceDetail: any;
  if (globalThis.mobile?.getIosDeviceDetail) {
    const nativeDetail = await globalThis.mobile.getIosDeviceDetail;
    deviceDetail = JSON.parse(nativeDetail);
  } else if (globalThis.mobile?.getAndroidDeviceDetail) {
    const nativeDetail = await globalThis.mobile.getAndroidDeviceDetail();
    deviceDetail = JSON.parse(nativeDetail);
  } else {
    deviceDetail = {
      deviceId: device_id,
      deviceName: "webpush",
    };
  }

  controller.setState({
    loading: true,
  });
  let date = {
    username: registerInfo?.username,
    password: registerInfo?.password,
    device_id: deviceDetail?.deviceId || "",
    ...(deviceDetail?.deviceType && { device_type: deviceDetail?.deviceType }),
    ...(deviceDetail?.device_name && {
      device_name: deviceDetail?.device_name,
    }),
    device_token: deviceDetail?.deviceToken || "",
  };
  const [res, error] = await RequestTokenAPI.post({
    data: date,
  });

  if (error) {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          ...(error?.non_field_errors && {
            message: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
          }),
        },
      },
    });
    return;
  } else {
    let typeUsername = "";

    if (checkValidEmail(res.username).valid) {
      typeUsername = "email";
    }

    Cookies.set("apiToken", res.token || "", { path: "/" });
    Cookies.set("userId", res.user_id, { path: "/" });
    Cookies.set("permit", res.permit, { path: "/" });

    localStorage.removeItem("storedHospitalList");
    localStorage.removeItem("storedPreferredHospital");

    controller.setState({
      apiToken: res.token || "",
      userId: res.user_id,
      permit: res.permit,
      registerInfo: {
        ...registerInfo,
        ...(res.cid && { valuecid: res.cid, typeId: "cid" }),
        ...(res.username && {
          username: res.username,
          typeUsername: typeUsername,
        }),
      },
    });
    if (globalThis.mobile?.saveDataLogin) {
      controller.setState({
        loading: false,
        lastLogin: {
          typeLogin: typeUsername,
        },
      });
      globalThis.mobile?.saveDataLogin(
        res.cid || "",
        res.username || "",
        res.token || "",
        res.permit || "",
        typeUsername
      );
    }

    date = {
      username: res.username,
      device_id: deviceDetail?.deviceId || "",
    };
  }
  // controller.setState({
  //   loading: true,
  // });

  // const [profileState, profileStateError] = await ProfileApi.post({
  //   data: date,
  //   pk: "state",
  // });

  controller.setState({
    loading: false,
    uiMode: "LOGIN",
  });

  CheckCurrentState(controller, params);

  // if (profileState) {
  //   CheckCurrentState(controller, {
  //     ...params,
  //     state: profileState?.state || "",
  //   });
  // } else {
  //   controller.setState({
  //     loading: false,
  //   });
  // }
};

export const HandleGotoMainScreen: Handler = async (controller, params) => {
  if (globalThis.mobile?.goToMainScreen) {
    globalThis.mobile?.goToMainScreen();
  } else {
    forceGoToFeed();
  }
};

export const HandleLogOut: Handler = async (controller, params) => {
  // *******************  path link login  *************************
  const { history } = params;
  console.log("history:", history);

  if (globalThis.mobile.signOutNative) {
    globalThis.mobile.signOutNative();
  }

  const [singOutResp, singOutError] = await SingOutAPI.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  if (singOutError) {
    Cookies.set("apiToken", "", { path: "/" });
    Cookies.set("userId", "", { path: "/" });
    Cookies.set("permit", "", { path: "/" });
    Cookies.set("bearer", "", { path: "/" });

    controller.setState({ loggedin: false, registerInfo: {}, profileInfo: {} });
    controller.app
      .auth()
      .signOut()
      .then(() => {
        clearData(controller);
        refreshUI(controller);
      })
      .catch((error: any) => {
        console.log("signOut error");
        console.log(error);
      });
    console.log("history:", history);
    history.push("/login/?app=MobRegister");
  } else {
    Cookies.set("apiToken", "", { path: "/" });
    Cookies.set("userId", "", { path: "/" });
    Cookies.set("permit", "", { path: "/" });
    Cookies.set("bearer", "", { path: "/" });

    controller.setState({ loggedin: false, registerInfo: {}, profileInfo: {} });
    controller.app
      .auth()
      .signOut()
      .then(() => {
        clearData(controller);
        refreshUI(controller);
      })
      .catch((error: any) => {
        console.log("signOut error");
        console.log(error);
      });
    console.log("history:", history);
    history.push("/login/?app=MobRegister");
  }
};

const clearData: Handler = (controller) => {
  controller.user = null;
  controller.setState({
    loggedin: false,
  });
};

const refreshUI: Handler = (controller) => {
  if (controller.app.auth().currentUser) {
    setLoggedinData(controller);
  }
};

const setLoggedinData: Handler = (controller) => {
  controller.user = controller.app.auth().currentUser;
  // controller.setState({loggedin: true})
};

///////////////////////////// const fun /////////////////////////

const CheckCurrentState: Handler = async (controller, params) => {
  const { registerInfo, apiToken, permit, uiMode, lastLogin } =
    controller.getState();
  const { state, history } = params;

  if (!apiToken) {
    controller.setState({
      loading: false,
      uiMode: "LOGIN",
      registerInfo: {
        ...registerInfo,
        state: state,
      },
    });
    history.push("/register/confirm-otp/?app=MobRegister");
    return;
  }

  const [res, error] = await SmartUserState.retrieve({
    apiToken: apiToken,
  });

  if (uiMode === "FORGOT_PASS") {
    controller.setState({
      loading: false,
    });
    history.push("/register/create-password/?app=MobRegister");
    return;
  }

  if (res) {
    controller.setState({
      loading: false,
    });
    if (res.has_password) {
      if (globalThis.mobile?.saveDataLogin) {
        await globalThis.mobile?.saveDataLogin(
          registerInfo?.valuecid || "",
          registerInfo?.username || "",
          apiToken || "",
          permit || "",
          lastLogin?.typeLogin || registerInfo?.typeUsername || "phoneNumber"
        );

        globalThis.mobile?.openPinCode(registerInfo?.username);
      } else {
        if (res.ekyc_status === "none") {
          history.push("/kyc/?app=MobRegister");
        } else if (!res.has_profile) {
          history.push("/kyc-step/edit-profile/?app=MobRegister");
        } else {
          forceGoToFeed();
        }
      }
    } else {
      controller.setState({
        loading: false,
      });
      history.push("/register/create-password/?app=MobRegister");
    }
    // switch (res) {
    //   case "ADD_OPTION":
    //     checkAuthorized(controller, params);
    //     break;
    //   case "COMPLETE":
    //     if (globalThis.mobile?.saveDataLogin) {
    //       globalThis.mobile?.saveDataLogin(
    //         registerInfo?.valuecid,
    //         registerInfo?.username,
    //         apiToken || "",
    //         permit || ""
    //       );
    //       globalThis.mobile?.pinCode(registerInfo?.username);
    //     } else {
    //       checkProfile(controller, params);
    //     }
    //     break;
    //   case "REGISTER":
    //     if (globalThis.mobile?.pinCode && globalThis.mobile?.saveDataLogin) {
    //       if (apiToken) {
    //         globalThis.mobile?.saveDataLogin(
    //           registerInfo?.valuecid,
    //           registerInfo?.username,
    //           apiToken || "",
    //           permit || ""
    //         );
    //         globalThis.mobile?.pinCode(registerInfo?.username);
    //       } else {
    //         controller.setState({
    //           loading: false,
    //           uiMode: "LOGIN",
    //           registerInfo: {
    //             ...registerInfo,
    //             state: state,
    //           },
    //         });
    //         history.push("/register/confirm-otp/?app=MobRegister");
    //       }
    //     } else {
    //       if (!apiToken) {
    //         controller.setState({
    //           loading: false,
    //           uiMode: "LOGIN",
    //           registerInfo: {
    //             ...registerInfo,
    //             state: state,
    //           },
    //         });
    //         history.push("/register/confirm-otp/?app=MobRegister");
    //       } else {
    //         checkKYC(controller, params);
    //       }
    //     }
    //     break;
    //   default:
    //     controller.setState({
    //       loading: false,
    //     });
    //     break;
    // }
  } else {
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: {
          ...registerInfo?.error,
          ...(error && { message: error || "get state error" }),
          ...(error?.detail && { message: error?.detail || "get state error" }),
        },
      },
    });
  }
};

// const checkAuthorized: Handler = async (controller, params) => {
//   const { registerInfo, apiToken, permit, device_id } = controller.getState();
//   const { history, state } = params;

//   const [res, error] = await MobileDeviceListAPI.list({
//     params: { device_id: device_id },
//     apiToken: apiToken || Cookies.get("apiToken"),
//   });

//   if (res) {
//     if (globalThis.mobile?.pinCode && globalThis.mobile?.saveDataLogin) {
//       globalThis.mobile?.saveDataLogin(
//         registerInfo?.valuecid,
//         registerInfo?.username,
//         apiToken || "",
//         permit || ""
//       );
//       globalThis.mobile?.pinCode(registerInfo?.username);
//     } else {
//       checkKYC(controller, params);
//     }
//   } else {
//     controller.setState({
//       loading: false,
//       uiMode: "LOGIN",
//       registerInfo: {
//         ...registerInfo,
//         state: state,
//       },
//     });
//     history.push("/register/confirm-otp/?app=MobRegister");
//   }
// };

// const checkKYC: Handler = async (controller, params) => {
//   const { registerInfo, apiToken, permit } = controller.getState();
//   const { history } = params;

//   const [status] = await GetStatuseKYC(controller, {});

//   if (!status.is_verified) {
//     history.push("/kyc/?app=MobRegister");
//     return;
//   } else {
//     checkProfile(controller, params);
//   }
// };

// const checkProfile: Handler = async (controller, params) => {
//   const { registerInfo, apiToken, permit } = controller.getState();
//   const { history } = params;

//   const [res, error] = await getUserProfile(controller, params);
//   if (res) {
//     const profile: profileInfo = { ...res };
//     let hasProfile = false;
//     if (
//       !profile.first_name ||
//       !profile.last_name ||
//       !profile.cid ||
//       !profile.dob ||
//       !profile.email ||
//       !profile.phone_no
//     ) {
//       hasProfile = true;
//     }
//     controller.setState({
//       profileInfo: { ...res },
//     });
//     if (hasProfile) {
//       controller.setState({
//         profileInfo: { ...res },
//       });
//       history.push("/kyc-step/edit-profile/?app=MobRegister");
//       return;
//     } else {
//       forceGoToFeed();
//     }
//   } else {
//     history.push("/kyc-step/edit-profile/?app=MobRegister");
//   }
// };

// const checkNewConsent: Handler = async (controller, params) => {
//   const [diagRule] = await CheckRequireDiagRuleView.get({
//     apiToken: controller.apiToken || Cookies.get("apiToken"),
//     params: { division: "1", code: "CS" },
//   });

//   if (diagRule) {
//     controller.setState({
//       loading: false,
//     });
//     if (globalThis.mobile?.startNewConsentForm) {
//       globalThis.mobile?.startNewConsentForm(`${diagRule?.publish_diag_rule}`);
//     }
//   } else {
//     controller.setState({
//       loading: false,
//     });
//   }
// };

// const CheckHnFromHospital: Handler = async (controller, params) => {
//   const [res, error] = await PatientHospital.list({
//     apiToken: controller.apiToken || Cookies.get("apiToken"),
//   });

//   // -controller.setState({
//   //   loading: true,
//   // });

//   if (res) {
//     var hospitalFilter = (res?.items || []).filter(
//       (item: any) => item.hn !== null || item.hn !== undefined
//     );
//     if (hospitalFilter.length > 0) {
//       syncProfileConsent(controller, params);
//     } else {
//       checkAutoHn(controller, params);
//     }
//   } else {
//     checkAutoHn(controller, params);
//   }
// };

// const checkAutoHn: Handler = async (controller, params) => {
//   const [res, error] = await AutoHNView.get({
//     apiToken: controller.apiToken || Cookies.get("apiToken"),
//   });

//   if (res?.hn?.length > 0) {
//     syncProfileConsent(controller, params);
//   } else {
//     controller.setState({
//       loading: false,
//     });
//   }
// };

// const syncProfileConsent: Handler = async (controller, params) => {
//   const [res, error] = await SyncProfileConsent.get({
//     apiToken: controller.apiToken || Cookies.get("apiToken"),
//   });

//   checkNewConsent(controller, params);
// };

/////////////////////////// checkValid /////////////////////////

const checkValidEmail = (email: string): { valid: boolean; message?: any } => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return {
    valid: regex.test(email),
    message: regex.test(email) ? "" : "incorrect",
  };
};

const forceGoToFeed = () => {
  const local = window?.location;
  local.href = `${local?.protocol}//${local?.host}/?app=MobFeed`;
};

const checkValidInput = (params: {
  text: string;
  prefix: string[];
  minLength?: number;
  length?: number;
  message?: string;
}): { valid: boolean; message?: any } => {
  const {
    text = "",
    prefix = [],
    minLength = 0,
    length = 0,
    message = "",
  } = params;
  if (minLength && text.length < minLength) {
    return { valid: false, message };
  }

  if (length && text.length !== length) {
    return { valid: false, message };
  }

  let errorKey: string = "";

  const validAll = prefix.every((value) => {
    const regex = new RegExp(`[${value}]`, "g");
    const test = regex.test(text);
    if (!errorKey && !test) {
      errorKey = value;
    }
    return test;
  });

  return { valid: validAll, message: validAll ? "" : message };
};

export const HandleUpdateStatuseKYC: Handler = async (controller, params) => {
  const [statusDetail] = await GetStatuseKYC(controller, {});

  return KYCInterfaceI.PatchStatuseKYC(controller, {
    is_verified: statusDetail.is_verified,
    data: { ...(statusDetail.data || {}), ...params },
    submit_count: statusDetail.submit_count - 1,
  });
};

export const HandleGetListOrganizations: Handler = async (
  controller,
  params
) => {
  const [response] = await ProxyOrganizationView.list({
    apiToken: controller.cookies.get("apiToken"),
  });

  controller.setState({
    organizationList: response?.items || [],
  });
};

export const HandleRegisterHN: Handler = async (controller, params) => {
  const state = controller.getState();
  const {
    [params.card]: key,
    [params.cardHospital]: hospitalKey,
    ...error
  } = state.errorMessage || {};

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [result] = await V4RegisterHNView.post({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: { hospital: params.code },
  });

  if (!result || result?.status !== "success") {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: "WAITING",
      },
    });
  } else {
    const [hospitalRes, hospitalErr] = await GetPatientGetHN(controller, {});

    if (hospitalErr?.patient) {
      return controller.setState({
        errorMessage: {
          ...error,
          [params.cardHospital]:
            REGEX_PATIENT.exec(hospitalErr?.patient[0])?.[1] || "",
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });
    }

    await HandleCheckConsentHospitalForm(controller, {
      ...params,
      hospitalCount: hospitalRes.length,
    });
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const HandleCheckConsentHospitalForm: Handler = async (
  controller,
  params
) => {
  await SyncAllProfileConsent.post({
    apiToken: controller.cookies.get("apiToken"),
  });

  const [userState] = await SmartUserState.retrieve({
    apiToken: controller.cookies.get("apiToken"),
  });

  if (userState.main_consent) {
    controller.handleEvent({
      message: "HandleCanceleKYC",
      params: { history: params.history },
    });
  } else {
    const [[diagRule], [consents]] = await Promise.all([
      CheckRequireDiagRuleView.get({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        params: { division: "1", code: "CS" },
      }),
      ListConsentsView.get({
        apiToken: Cookies.get("apiToken"),
      }),
    ]);

    const hospitals: any[] = userState.hospital_profiles || [];
    const consent =
      (consents || []).find(
        (item: any) => String(params.code) === item.organization_code
      ) || null;

    // const selectConsent =
    //   hospitals.filter((item) => !item.is_consent).length >= 2;

    const selectConsent = params.hospitalCount >= 2;

    const consentParams = !selectConsent
      ? `&published_diag_rule=${
          consent?.published_diag_rule
        }&organization_name=${consent?.organization_name || ""}`
      : "";
    const url = !selectConsent ? "/consent-hospital-form" : "/consent-form";

    globalThis.location.href = `${url}/${
      diagRule?.publish_diag_rule
    }/?app=${"IH"}${consentParams}`;
  }
};

export const HandleCheckPatientHasHospitial: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const {
    [params.card]: key,
    [params.cardHospital]: hospitalKey,
    ...error
  } = state.errorMessage || {};

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.card]: true,
    },
  });

  const appointment = params.history.location.state?.appointment;

  if (appointment) {
    await controller.handleEvent({
      message: "HandleCheckMakeAppointment" as any,
      params,
    });
  } else {
    const [userState] = await SmartUserState.retrieve({
      apiToken: controller.cookies.get("apiToken"),
    });

    const hospitals: any[] = userState.hospital_profiles || [];

    const [hospitalRes, hospitalErr] = await GetPatientGetHN(controller, {});
    console.log("hospitalRes:", hospitalRes);

    if (hospitalErr?.patient) {
      return controller.setState({
        errorMessage: {
          ...error,
          [params.cardHospital]:
            REGEX_PATIENT.exec(hospitalErr?.patient[0])?.[1] || "",
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });
    }

    const isRegister = !hospitalRes?.length;

    if (isRegister) {
      // ยังไม่ผูก HN
      params.history.push("/register-hn/?app=MobRegister");
    }
    // ผูก HN แล้ว
    else {
      let code = state.selectedHospital?.code;

      if (!code) {
        code = hospitals[0].hospital_code;
      }

      await controller.handleEvent({
        message: "HandleCheckConsentHospitalForm" as any,
        params: {
          code,
          history: params.history,
          hospitalCount: hospitalRes.length,
        },
      });
    }
  }

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.card]: false,
    },
    errorMessage: { ...error },
  });
};

export const HandleGetStatuseKYC: Handler = async (controller, params) => {
  const state = controller.getState();

  const [[status], [userStatus]] = await Promise.all([
    GetStatuseKYC(controller as any, {}),
    SmartUserState.retrieve({
      apiToken: controller.cookies.get("apiToken"),
    }),
  ]);

  controller.setState({
    eKYCStatus: { ...(status || {}), ekyc_too_many: userStatus?.ekyc_too_many },
    // errorMessage: {
    //   ...state.errorMessage,
    //   [params.card]: { status: "success" },
    // },
  });
};

export const HandleCheckMakeAppointment: Handler = async (
  controller,
  params
) => {
  const history = params.history;
  const locState = history.location.state || {};
  const appointment = locState.appointment;

  if (appointment) {
    const state =
      typeof appointment.state === "string"
        ? JSON.parse(appointment.state)
        : appointment.state;
    const locationState = { ...state.locationState };
    let response: any[] | null = null;

    const [patientRes, patientErr] = await MyProxyPatientView.retrieve({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
    });

    delete state.locationState;

    const rc = await RegisterHNAndCheckConsent(controller as any, {
      params: { ...state, history: history },
      patientErr,
      patientRes,
      medServiceList: [],
      locationState,
    });

    if (rc && rc[0] !== "pending") {
      response = rc;
    } else if (patientRes?.hn) {
      response = [];
    }

    if (response) {
      const index = (locState.routes || [])
        .reverse()
        .findIndex((route: string) => route === "/confirm-appointment");

      const stringifyState = JSON.stringify({
        ...state,
        locationState,
        response,
      });

      if (index !== -1) {
        history.go(-(index + 1));

        setTimeout(() => {
          history.replace({
            pathname: `${appointment.pathname}`,
            search: `${appointment.search}&complete=true`,
            state: stringifyState,
          });

          globalThis.history.go();
        }, 10);
      } else {
        history.push({
          pathname: "/makeappointmentUnsign",
          state: stringifyState,
          search: "?app=MobAppointment",
        });

        globalThis.history.go();
      }
    }
  }
};

export const HandlerPostRetrieveProfile: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [`${params.card}${params.btnAction}`]: true,
    },
  });

  await SyncAllProfileConsent.post({
    apiToken: controller.cookies.get("apiToken"),
  });

  const [userProfile] = await UserProfileAPI.retrieve({
    apiToken: controller.cookies.get("apiToken"),
  });

  await PostRetrieveProfile(controller, {
    data: {
      username: userProfile.username,
      hn: params.hn,
      hospital: params.code,
    },
  });

  if (params.initHospital) {
    await controller.handleEvent({
      message: "GetListHospital",
      params: { sendToNative: true, apiv3: true },
    });

    controller.handleEvent({
      message: "HandleSelectedHospital",
      params: {
        data: {
          code: params.code,
          name: params.name,
          hn: params.hn,
        },
      },
    });
  }

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [`${params.card}${params.btnAction}`]: false,
    },
  });
};

export const GetMyProfileDetail: Handler = async (controller, params) => {
  const [res] = await ProxyMyProfile.get({
    apiToken: controller.cookies.get("apiToken"),
  });

  controller.setState({ myProfileDetail: res || {} });
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetPatientGetHN: Handler = async (controller, params) => {
  return V3PatientGetHN.get({
    apiToken: Cookies.get("apiToken"),
  });
};

export const GetStatuseKYC: Handler = async (controller, params) => {
  let result: any[] = [{ data: null }];

  try {
    result[0] = await axios({
      method: "get",
      url: `${CONFIG.API_HOST}/users/apis/ekyc/status/`,
      headers: {
        Authorization: `Token ${Cookies.get("apiToken")}`,
      },
    });
  } catch (error) {}

  return [result[0].data];
};

const PostRetrieveProfile: Handler = async (controller, params) => {
  return SmartRetrieveProfile.post({
    data: params.data,
    apiToken: controller.cookies.get("apiToken"),
  });
};

const GetMasterOptions: Handler = async (controller, params) => {
  const gatGender = (lang: string) => {
    return ProfileApi.get({
      apiToken: controller.cookies.get("apiToken"),
      pk: "gender",
      extra: { headers: { "Accept-Language": lang } },
    });
  };

  const gatPrename = (lang: string) => {
    return ProfileApi.get({
      apiToken: controller.cookies.get("apiToken"),
      pk: "prename",
      extra: { headers: { "Accept-Language": lang } },
    });
  };

  const gatNationality = (lang: string) => {
    return ProfileApi.get({
      apiToken: controller.cookies.get("apiToken"),
      pk: "nationality",
      params: {limit:350},
      extra: { headers: { "Accept-Language": lang } },
    });
  };

  const [
    [genderTh],
    [genderEn],
    [prenameTh],
    [prenameEn],
    [nationalityTh],
    [nationalityEn],
  ] = await Promise.all([
    gatGender("th"),
    gatGender("en"),
    gatPrename("th"),
    gatPrename("en"),
    gatNationality("th"),
    gatNationality("en"),
  ]);

  return {
    gender: { th: genderTh?.items || [], en: genderEn?.items || [] },
    prename: { th: prenameTh?.items || [], en: prenameEn?.items || [] },
    nationality: {
      th: nationalityTh?.items || [],
      en: nationalityEn?.items || [],
    },
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const FormattedProfileInfo = (params: any) => {
  let email = "";
  const { profile, options } = params;

  if (profile?.email && !profile?.email.includes("@thevcgroup")) {
    email = profile?.email;
  }

  const nStr = (profile?.nationality?.split(" ")?.[0] || "")?.toLowerCase();
  const nationality = nStr.charAt(0).toUpperCase() + nStr.slice(1);

  const gStr = profile?.gender?.split(" ")?.[0]?.toLowerCase();
  const gender = gStr.charAt(0).toUpperCase() + gStr.slice(1);

  return {
    ...profile,
    email,
    gender: findGender(gender),
    // gender: findOptionByName({
    //   th: options.gender.th,
    //   en: options.gender.en,
    //   value: gender,
    //   lang: params.lang,
    // }),
    nationality: findOptionByName({
      th: options.nationality.th,
      en: options.nationality.en,
      value: nationality,
      lang: params.lang,
    }),
    pre_name: findOptionByName({
      th: options.prename.th,
      en: options.prename.en,
      value: profile.pre_name,
      lang: params.lang,
    }),
    ecp_full_name: `${profile?.ecp_first_name || ""}${
      profile?.ecp_last_name ? ` ${profile.ecp_last_name}` : ""
    }`,
  };
};

const findGender = (value: string) => {
  const male = ["Male", "male", "ชาย", "1", "MALE"];
  const female = ["Female", "female", "หญิง", "2", "FEMALE"];
  if (male.includes(value)) {
    return 1;
  } else if (female.includes(value)) {
    return 2;
  } else {
    return "";
  }
};

const findOptionByName = (params: {
  th: any[];
  en: any[];
  value: string;
  lang: string;
}) => {
  const { th, en, value } = params;

  const items = { th, en } as any;
  const invert = { th: "en", en: "th" } as any;

  const data = Array(en.length)
    .fill("")
    .flatMap((_, index) => {
      if (compareUpperCase(en[index]?.name, value)) {
        return [{ index, lang: "en" }];
      } else if (compareUpperCase(th[index]?.name, value)) {
        return [{ index, lang: "th" }];
      } else {
        return [];
      }
    })[0];

  const lang = params.lang === data?.lang ? data.lang : invert[data?.lang];

  return data?.lang ? items[lang][data.index]?.name : value;
};

export const compareUpperCase = (text1: string = "", text2: string = "") => {
  const MISS = ["นางสาว", "น.ส."];
  const GIRL = ["เด็กชาย", "ด.ช."];
  const BOY = ["เด็กหญิง", "ด.ญ."];

  const isMatch = [MISS, GIRL, BOY].some(
    (items) => items.includes(text2) && items.includes(text1)
  );

  if (isMatch) {
    return true;
  }

  return text1?.toUpperCase() === text2?.toUpperCase();
};

export const CheckUserProfileRequiredField: Handler = (
  controller,
  params: any
) => {
  const passport = params.cid?.length !== 13;
  const checkName = passport
    ? true
    : !!params?.last_name.replaceAll(" ","") && !!params?.first_name.replaceAll(" ","");

  return (
    checkName &&
    (!!params?.dob || !!params.birthdate) &&
    !!params?.pre_name &&
    !!params?.gender &&
    !!params?.nationality
  );
};
